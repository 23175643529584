import { NavItem, NavLink } from "reactstrap"
import { company, connection, convert, deleteIcon, email, modifyIcon, role, tenant, users } from "./icons"
import { useLocation, useNavigate } from "react-router-dom"
import { useApp } from "../components/app"
import { useAuth } from "../components/auth"

enum MenuShow {
    ALWAYS,
    ON_Conversation,
    NOT_Conversation
}
interface Menu {
    name: string,
    route: string,
    icon: any
    menuShow: MenuShow
}



const MenuList: Menu[] = [
    {
        name: "Conversion",
        route: "conversation",
        icon: company.icon,
        menuShow: MenuShow.NOT_Conversation,
    },
    {
        name: "Function",
        route: "job",
        icon: convert.icon,
        menuShow: MenuShow.ON_Conversation
    },
    // {
    //     name: "Balance sheet",
    //     route: "balanceSheet",
    //     icon: connection.icon,
    //     menuShow: MenuShow.ON_Conversation
    // },
    {

        name: "Delete",
        route: "delete",
        icon: deleteIcon.icon,
        menuShow: MenuShow.ON_Conversation
    },
    {
        name: "Modification",
        route: "modification",
        icon: modifyIcon.icon,
        menuShow: MenuShow.ON_Conversation
    },
    {
        name: "User master",
        route: "user",
        icon: users.icon,
        menuShow: MenuShow.NOT_Conversation
    },
    {
        name: "Role master",
        route: "role",
        icon: role.icon,
        menuShow: MenuShow.NOT_Conversation
    },
    {
        name: "Email template",
        route: "emailTemplate",
        icon: email.icon,
        menuShow: MenuShow.NOT_Conversation
    },
]

export const GetMenuList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const app = useApp()
    const auth = useAuth()
let loginType = auth?.user?.loginType 
    

    return MenuList.map((item) => {
        let isActivePath = (location.pathname === `/${item.route}` || (location.pathname === '/' && item.route === "conversation")) ? "active" : ""
        switch (item.menuShow) {
            case MenuShow.ON_Conversation: {
                if (app.conversation) {

                    return <NavItem>
                        <NavLink onClick={() => {
                            if (auth.user?.role == "User") {
                                navigate(`${loginType}/${item.route}`);
                            } else {
                                navigate(`/${item.route}`);
                            }
                        }} className={isActivePath}>
                            {item.icon && <span>{item.icon}</span>}{item.name}
                        </NavLink>
                    </NavItem>
                }
                return <></>

            }
            case MenuShow.NOT_Conversation: {
                if (!app.conversation && (auth.user?.role == "Admin" || !["role", "emailTemplate"].includes(item.route))) {
                    return <NavItem>
                        <NavLink onClick={() => {
                            if (auth.user?.role == "User") {
                                navigate(`${loginType}/${item.route}`);
                            } else {
                                navigate(`/${item.route}`);
                            }
                        }} className={isActivePath}>
                            {item.icon && <span>{item.icon}</span>}{item.name}
                        </NavLink>
                    </NavItem>
                }
                return <></>
            }
            default: {
                return <NavItem>
                    <NavLink onClick={() => {
                        navigate(`/${item.route}`);
                    }} className={isActivePath}>
                        {item.icon && <span>{item.icon}</span>}{item.name}
                    </NavLink>
                </NavItem>
            }
        }

    })
}