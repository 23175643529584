import { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from 'reactstrap';
import Logo from "../../assets/images/logo-dark.png"
import { useLocation } from 'react-router-dom';


export const Header = () => {
  const location = useLocation()

  let loginType: string | undefined | null = location.pathname.split('/')[1]
  if (!['odoo', "QBO", "xero", "zoho", "qbo"].includes(loginType)) {
    loginType = undefined
  }
  if (loginType === "qbo") {
    loginType = "QBO"
  }
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  console.log("pageName", location.pathname)
  return <header className="header user_header">
    <div className='container'>
      <Navbar expand="md">
        <NavbarBrand href="/">
          <img
            alt="logo"
            src={Logo}
          />
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggle} /> */}
        <Nav className="ml-auto" navbar>
          <NavItem>
            {
              <NavLink href={loginType ? `/${loginType}/login` : '/login'} >Go to Login</NavLink>
            }
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  </header>
}
