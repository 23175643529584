import PageLayout from "../layout/page"
import { arrowLeft, rightArrow } from "../../constant/icons"
import { Button } from "reactstrap";
import { useApp } from "../../components/app";
import { useNavigate } from "react-router-dom";
import { DataView } from "../../components/table/table";
import { useState } from "react";
import { DeleteList } from "./delete";
import modify_img from "../../assets/images/modify_img-2.png";
import { ModifictionList } from "./modifiction";

const ModificationInfo = () => {
  const app = useApp()
  let navigate = useNavigate()
  if (!app.conversation) {
    navigate('/')
  }
  const [isModification, setIsModification] = useState(false)
  return (<PageLayout
    title={<><Button color="link" onClick={() => navigate('/')} className="btn-back">{arrowLeft.icon} </Button>Modification</>}

    View={isModification
      ? <ModifictionList /> : <>
        <div className="modify_top_section">
          <div className="modify_text">
            <h3>Streamlined File Modification</h3>
            <p>Unleash the true potential of your digital assets with MMC : Excel to XERO tool, the all-in-one solution for effortless file customization. Whether you're tweaking documents, enhancing images, or fine-tuning data, our user-friendly platform empowers you to modify files with unparalleled ease.</p>
          </div>
          <div className="modify_img">
            <img src={modify_img} alt="modify" />
          </div>
        </div>
        <div className="modify_bottom_section">
          <h2>How Modify Works in System?</h2>
          <ul className="process_card">
            <li>
              <span>1</span>
              <h3>Select Transaction Type</h3>
              <p>Unleash the true potential of your digital assets with MMC : Excel to XERO tool, the all-in-one solution for effortless file customization.</p>
            </li>
            <li>
              <span>2</span>
              <h3>Upload the Updated File</h3>
              <p>Unleash the true potential of your digital assets with MMC : Excel to XERO tool, the all-in-one solution for effortless file customization.</p>
            </li>
            <li>
              <span>3</span>
              <h3>Align File Headers and Modify</h3>
              <p>Unleash the true potential of your digital assets with MMC : Excel to XERO tool, the all-in-one solution for effortless file customization.</p>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-end modify_btn">
          <Button onClick={() => setIsModification(true)}>Let's Modify<span>{rightArrow.icon}</span></Button>
        </div>
      </>}
  />
  );
}
export { ModificationInfo }