import { ErrorMessage, Field, Formik } from "formik";
import {
  loginValidationSchema,
  signUpValidationSchema,
} from "./validationSchema";
import { Row, Col, Label, Button, FormFeedback, Spinner } from "reactstrap";
import { AppMainDarkLogo } from "../../constant/logo";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLoaderHelper } from "../../helper/loaderHelper";
import signin_side_img_1 from "../../assets/images/signin_side_img_1.png";
import signin_side_img_2 from "../../assets/images/signin_side_img_2.png";
import { hideIcon, viewIcon } from "../../constant/icons";
import ReactSelect from "react-select";
import { post } from "../../helper/api";
import { useState } from "react";

const Usersignup = (props: any) => {
  const location = useLocation();
  let loginType: string | undefined | null = location.pathname.split("/")[1];
  if (!["odoo", "QBO", "xero", "zoho", "qbo"].includes(loginType)) {
    loginType = undefined;
  }
  if (loginType === "qbo") {
    loginType = "QBO";
  }

  const initialValues: any = {
    emailId: "",
    name: "",
    password: "",
    mobileNo: "",
    appCode: loginType ? loginType : "",
  };
  const navigate = useNavigate();
  const loading = useLoaderHelper();
  const appCodeList: any = [
    {
      value: "xero",
      label: "xero",
    },
    {
      value: "QBO",
      label: "QBO",
    },
    {
      value: "zoho",
      label: "zoho",
    },
  ];
  const [isPassword, setPassword] = useState(false);

  function navigateBasedOnApp(values: any) {
    const appCode = (values?.appCode || "").toLowerCase();
    switch (appCode) {
      case "qbo":
        navigate("/qbo/login");
        break;
      case "xero":
        navigate("/xero/login");
        break;
      default:
        navigate("/zoho/login");
    }
  }

  return (
    <div className="login-page theme_01 user_login user_signup">
      <Row>
        <Col lg={5} className="login-img">
          {/* <img alt="Sign in" src={sign_bg} /> */}
          <div className="login_img_text">
            <h2>
              Seamless Conversion, <span>Effortless Access</span>
            </h2>
            <div className="side_img_box">
              <img className="file_img" alt="Sign in" src={signin_side_img_1} />
              <img
                className="charecter_img"
                alt="Sign in"
                src={signin_side_img_2}
              />
            </div>
          </div>
        </Col>

        <Col lg={7} className="login-form">
          <div className="login-main small-scroll">
            <div className="loging-head">
              <img alt="Logo" src={AppMainDarkLogo} />
            </div>
            <div className="loging-content">
              <h2>Sign Up</h2>
              <p>Enter your details to sign up</p>
              <Formik
                validationSchema={signUpValidationSchema}
                initialValues={initialValues}
                onSubmit={async (values: any) => {
                  let formDubmit = {
                    ...values,
                    mobileNo: String(values.mobileNo),
                  };
                  const result = await post(
                    "/signUp",
                    formDubmit,
                    undefined,
                    loading
                  );

                  if (result?.status) {
                    navigateBasedOnApp(values);
                  }
                }}
              >
                {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                  <div className="form-horizontal form-login row">
                    <div className="form-group">
                      <Field
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="form-control"
                        type="text"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger signUp-validation"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="emailId"
                        name="emailId"
                        placeholder="Email Name"
                        className="form-control"
                        type="email"
                      />

                      <ErrorMessage
                        name="emailId"
                        component="div"
                        className="text-danger signUp-validation"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="phoneno"
                        name="mobileNo"
                        placeholder="Phone No"
                        className="form-control"
                        type="Number"
                      />
                      <ErrorMessage
                        name="mobileNo"
                        component="div"
                        className="text-danger signUp-validation"
                      />
                    </div>

                    <div className="form-group select_app_dropdown">
                      <ReactSelect
                        name="appCode"
                        onChange={(newValue: any) => {
                          setFieldValue("appCode", newValue.value);
                        }}
                        options={appCodeList}
                        value={appCodeList?.find(
                          (x: any) => x.value === values.appCode
                        )}
                        isDisabled={loginType ? true : false}
                      />
                      <ErrorMessage
                        name="appCode"
                        component="div"
                        className="text-danger signUp-validation"
                      />
                    </div>
                    <div className="form-group">
                      <Label className="form-label" htmlFor="password"></Label>
                      <Field
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="form-control"
                        type={isPassword ? "text" : "password"}
                        title="Password must contain at least 1 uppercase,1 lowercase,1 digit and 1 special symbol with minimum 8 characters and maximum 16 characters."
                      />
                      <button
                        className="btn btn-link pass_view_btn"
                        onClick={() => setPassword(!isPassword)}
                      >
                        {isPassword ? viewIcon.icon : hideIcon.icon}
                      </button>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger signUp-validation"
                      />
                    </div>
                    <Col lg="12">
                      <Button
                        color="primary"
                        block
                        className="w-100"
                        type="submit"
                        onClick={() => handleSubmit()}
                      >
                        {loading.dataIsLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Col>
                    <Col lg="12" className="text-center">
                      <Link
                        to={loginType ? `/${loginType}/login` : "/login"}
                        className="pt-3 d-inline-block mx-auto"
                      >
                        Back to Login
                      </Link>
                    </Col>
                  </div>
                )}
              </Formik>
            </div>
            <div className="text-center copy-right w-100">
              <p>Copyright © {new Date().getFullYear()} by MMC Convert</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Usersignup;
