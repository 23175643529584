import {Navigate, Outlet} from 'react-router-dom'
import { useAuth } from './auth'

export const RequireAuth = () => {
    const auth:any=useAuth()
    if(!auth.user)
    {
        return <Navigate to='/login'/>
    }
  return <Outlet/>
}
