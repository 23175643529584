import { Outlet } from "react-router-dom";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { Header } from "./header";
import { Loader } from "./loader";
import { useLoaderHelper } from "../../helper/loaderHelper";

export const Layout = () => {
  const loading = useLoaderHelper();

  return (
    <>
      {loading.dataIsLoading && <Loader></Loader>}
      <main className="theme-01 sidebar-collapse">
        <Header></Header>
        <Aside></Aside>

        <main className="main">
          <Outlet />
          <Footer></Footer>
        </main>
      </main>
    </>
  );
};
