import { DataType } from "ka-table/enums";
import { TableColumn } from "../../components/table/table";

import { ProgressStatusLabel } from "./components/progress";

import { ActionButton } from "./components/actionButtons";
import { ErrorLogView } from "./components/errorLogView";
import { post } from "../../helper/api";
const getFilenameFromResponseHeaders = (response: any) => {
  try {
    const contentDisposition = response.headers["content-disposition"];
    const matches = contentDisposition.match(/filename="(.*?)"/);
    return matches ? matches[1] : "errorLogFile.csv"; // Set a default filename if not found
  } catch { }
  return "errorLogFile.csv";
};
export const columns = (actions: any): TableColumn[] => [
  {
    key: "id",
    title: "id",
    dataType: DataType.Number,
    visible: false,
  },
  {
    key: "jobName",
    title: "Function name",
    dataType: DataType.String,
  },
  {
    key: "progress",
    title: "status",
    hideHeaderFilter: true,
    component: ProgressStatusLabel,
    callBack: (row) => {
      actions.api.status({
        id: row.id,
      });
    },
  },
  {
    key: "createdDate",
    title: "Created date",
    dataType: DataType.String,
  },
  {
    key: "action",
    title: "",
    hideHeaderFilter: true,
    component: ActionButton,
    style: {
      textAlign: "center",
    },
    callBack: async (row: any) => {
      if (row.clickType == "details" || row.clickType == "remove") {
        actions.actionClick(row)
        return false
      }

      if (row.clickType == "download") {
        const response = await post(
          "/exportJobErrors",
          { id: row.id },
          true,
          actions.loader
        );

        if (response.status === 200 && response?.data?.status === undefined) {
          const blob = new Blob([response.data], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${row.jobName}_errorLog.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
];

export const jobErrorColumns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "errorMessage",
      title: "",
      component: ErrorLogView,
      callBack: async (row) => {
        await post("/repostjobpayload", row, undefined, actions.loader);
        actions.api.mutate();
      },
    },
  ];
};
