import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { clickIcon, downloadIcon, logoutDuotone, plus } from "../../constant/icons";
import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import PageLayout from "../layout/page";
import { useApi } from "../../helper/apiHook";
import { useEffect } from "react";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { AddUpdateCompany } from "./addUpdate";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../components/app";
import { message } from "../../components/toast";
import { NOT_ASSOCIATED, conversation_is_deActivated } from "./message";
import React, { useState } from 'react';
import { useLoaderHelper } from "../../helper/loaderHelper";
import { useAuth } from "../../components/auth";


const CompanyList = () => {
  const app = useApp();
  const api: any = useApi("company", true, {}, {}, app.isLoading);
  const loader = useLoaderHelper()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState: any) => !prevState);
  const AddUpdate = useAddUpdate();
  const navigate = useNavigate();
  const auth = useAuth()

  const searchParams = new URLSearchParams(window.location.search);
  const errorMsg: any = searchParams.get('errorMsg');
  useEffect(() => {
    if (errorMsg) {
      message(
        "error",
        errorMsg
      );
      const timer = setTimeout(() => {
        if (auth.user?.role == "User") {
          navigate(`/${auth?.user?.loginType}/conversation`);
        } else {
          navigate("/conversation");
        }
      }, 3000); // 3 seconds in milliseconds
      return () => clearTimeout(timer);

    }
  }, [errorMsg]);

  useEffect(() => {
    api.mutate();
    app.updateConversation(null);
  }, [app.currentApp?.appCode]);
  const onRowDoubleClick = (e: any, event: any) => {
    onJobView(event.childProps.rowData?.tenant?.[0]?.id, event.childProps.rowData)
  };
  const onJobView = (id: any, row: any) => {
    if (!row.isActivate) {
      message("info", conversation_is_deActivated);
    }
    else if (id > 0) {
      app.updateConversation(row);
      if (auth.user?.role == "User") {
        navigate(`${auth.user?.loginType+"/job"}` , {
          state: { action: 'save', data: row },
        });
      } else {
        navigate("/job", {
          state: { action: 'save', data: row },
        });
      }

    } else {
      message("info", NOT_ASSOCIATED);
    }
  }
  const actions = {
    openAddEditFrom: AddUpdate.openAddEditFrom,
    conversation: app.updateConversation,
    onJobView: onJobView
  }
  return AddUpdate.viewAddUpdate ? (
    <AddUpdateCompany
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
  ) : (
    <PageLayout
      title="List of Company"
      item={
        <div className="d-flex gap-2">
          <Button
            color="primary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                date: null,
                remark: "",
                email: "",
                name: "",
                contact_person: [],
                company_settings: {
                  date: undefined,
                  dateFormat: `DD/MM/YYYY`
                },
              })
            }
          >
            {plus.icon}Add New Company
          </Button>
          <Dropdown className="template_download" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle color='secondary'>
              <span>
                {downloadIcon.icon}
              </span>
              {app.currentApp?.templateUrl && <a className="m-0" href={app.currentApp?.templateUrl} target="_blank" style={{ color: 'white' }}>Download Templates</a>}
            </DropdownToggle>
            {/* <DropdownMenu>
                    <DropdownItem>
                      All
                    </DropdownItem>
                    <DropdownItem>
                      <span>{downloadIcon.icon}</span>
                      Currencies
                    </DropdownItem>
                    <DropdownItem>
                      <span>{downloadIcon.icon}</span>
                      Tax Master
                    </DropdownItem>
                    <DropdownItem>
                      <span>{downloadIcon.icon}</span>
                      Purchase Orders
                    </DropdownItem>
                    <DropdownItem>
                      <span>{downloadIcon.icon}</span>
                      Allocation
                    </DropdownItem>
                    <DropdownItem>
                    <span>{downloadIcon.icon}</span>
                      Supplier Credit Note
                    </DropdownItem>
                </DropdownMenu> */}
          </Dropdown>
        </div>
      }
      View={
        <>
          <DataView
            columns={columns(api, actions, loader)}
            rows={api.data}
            currentPage={api.currentPage}
            onRowDoubleClick={onRowDoubleClick}
            rowCount={api.rowCount}
            limit={api.limit}
            updatePagination={api.updatePagination}
          />
          <div className="doubleclick_note">
            <p><span>{clickIcon.icon}</span>View Function double-click on row</p>
          </div>
        </>

      }
    />
  );
};

export { CompanyList };
