import PageLayout from "../layout/page";
import {
  arrowLeft,
  clickIcon,
  deleteIcon,
  detailInfo,
  downloadIcon,
  plus,
} from "../../constant/icons";
import { Button, Progress } from "reactstrap";
import { useState } from "react";
import { useApp } from "../../components/app";
import { useLocation, useNavigate } from "react-router-dom";
import { JobDetails } from "./jobDetails";
import { DataView } from "../../components/table/table";
import { useApi } from "../../helper/apiHook";
import { columns } from "./columns";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { AddUpdateJob } from "./addUpdate/addUpdate";
import { useLoaderHelper } from "../../helper/loaderHelper";
import { post } from "../../helper/api";
import { message } from "../../components/toast";

const JobList = () => {
  const app = useApp();
  const location = useLocation();
  let navigate = useNavigate();
  if (!app.conversation && location?.state?.data) {
    app.updateConversation(location?.state?.data);
  }
  if (!app.conversation && !location?.state?.data) {
    navigate("/");
  }
  const loading = useLoaderHelper();
  const [isJobDetailsOpen, setJobDetailsOpen] = useState<boolean>(false);
  const addUpdate = useAddUpdate();
  const api = useApi(
    "job",
    true,
    { tenantId: app.conversation?.tenant?.[0]?.id || 0 },
    {
      revalidateOnFocus:
        addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : true,
      refreshInterval:
        addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : 3000,
      revalidateIfStale:
        addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : true,
      revalidateOnReconnect:
        addUpdate.viewAddUpdate || isJobDetailsOpen ? undefined : true,
    },
    undefined,
    false
  );

  const actionClickButton = async (data: any) => {
    if (data.clickType == "details") {
      setOpenJobId(data);
      jobDetailsToggle();
    } else if (data.clickType == "remove") {
      const result = await post(
        "/jobDelete",
        { id: data.id },
        undefined,
        loading
      );
      // if (result?.status) {

      // }
    }
  };

  const actionClick = actionClickButton;

  //---Job Details Open Close

  const [openJobId, setOpenJobId] = useState(0);
  const jobDetailsToggle = () => setJobDetailsOpen(!isJobDetailsOpen);
  //---

  //-------------------Current Selected App

  //-------------------

  const onRowDoubleClick = (e: any, event: any) => {
    setOpenJobId(event.childProps.rowData);
    jobDetailsToggle();
  };
  const getRefreshData = () => {
    api.mutate();
  };

  return addUpdate.viewAddUpdate ? (
    <AddUpdateJob
      closeAddUpdate={addUpdate.closeAddEditFrom}
      api={api}
      value={addUpdate.currentValue}
    />
  ) : (
    <PageLayout
      title={
        <>
          <Button
            color="link"
            onClick={() => navigate("/")}
            className="btn-back"
          >
            {arrowLeft.icon}{" "}
          </Button>
          Function
        </>
      }
      item={
        <Button
          color="primary"
          onClick={() => addUpdate.openAddEditFrom(false)}
        >
          {plus.icon}Add New Function
        </Button>
      }
      View={
        <>
          {isJobDetailsOpen && (
            <JobDetails
              refreshData={getRefreshData}
              isJobDetailsOpen={isJobDetailsOpen}
              jobDetailsToggle={jobDetailsToggle}
              openJobId={openJobId}
            />
          )}
          <DataView
            columns={columns({ api, actionClick })}
            rows={api.data}
            rowCount={api.rowCount}
            currentPage={api.currentPage}
            limit={api.limit}
            onRowDoubleClick={onRowDoubleClick}
            updatePagination={api.updatePagination}
          />
          <div className="doubleclick_note">
            <p>
              <span>{clickIcon.icon}</span>View Function double-click on row
            </p>
          </div>
        </>
      }
    />
  );
};
export { JobList };
