import { Button, Col, FormGroup, Label } from "reactstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { arrowLeft } from "../../constant/icons";
import ReactSelect from "react-select";
import PageLayout from "../layout/page";
import { userValidationSchema } from "./validationSchema";
import { useLoaderHelper } from "../../helper/loaderHelper";
import { useAuth } from "../../components/auth";

const AddUpdateUser = (props: any) => {
  const { api, roleList, value, closeAddUpdate } = props;
  const loader = useLoaderHelper();
  const ListOfRole = roleList?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
  const auth = useAuth();
  return (
    <PageLayout
      title={
        <>
          <Button
            color="link"
            className="btn-back"
            onClick={() => props.closeAddUpdate()}
          >
            {arrowLeft.icon}{" "}
          </Button>
          {value?.id > 0 ? "Edit" : "Add"} User
        </>
      }
      View={
        <Formik
          validationSchema={userValidationSchema}
          initialValues={value}
          onSubmit={(values: any, actions: any) => {
            if (values.id > 0) {
              api.updateValue(values, loader).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                }
              });
            } else {
              api.create(values).then((result: any) => {
                if (result?.status) {
                  closeAddUpdate();
                }
              });
            }
          }}
        >
          {(props) => (
            <Form className="form-row">
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  disabled={value?.id > 0}
                  className="form-control"
                  id="userName"
                  name="userName"
                  placeholder="User Name"
                />
                <Label>User name</Label>
                <ErrorMessage
                  name="userName"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                />
                <Label>Name</Label>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup className="col-4 form-floating mb-0">
                <ReactSelect
                  name="roleId"
                  onChange={(newValue: any) => {
                    props.setFieldValue("roleId", newValue?.value);
                  }}
                  options={ListOfRole}
                  value={ListOfRole?.find(
                    (x: any) => x.value === props.values.roleId
                  )}
                  isDisabled={auth?.user?.role == "User" ? true : false}
                />
                <ErrorMessage
                  name="roleId"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  id="emailId"
                  name="emailId"
                  placeholder="Email"
                  disabled={value?.id > 0}
                />
                <Label>Email</Label>
                <ErrorMessage
                  name="emailId"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  id="mobileNo"
                  name="mobileNo"
                  placeholder="Mobile No"
                />
                <Label>Mobile No.</Label>
                <ErrorMessage
                  name="mobileNo"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <Col lg="12" className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  {value?.id > 0 ? "Update" : "Add"}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
export { AddUpdateUser };
