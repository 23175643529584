import * as xlsx from "xlsx"
import { FileUpload } from "../../type";
export function generateUniqueId() {
  const timestamp = new Date().getTime(); // Get the current timestamp
  const random = Math.random() * 1000000; // Generate a random number

  // Combine the timestamp and random number to create a unique ID
  const uniqueId = `${timestamp}${random}`;

  return uniqueId;
}

export const getSheetNames=async (props:any): Promise<FileUpload[]> => {
    const {fileUpload}=props
    const promises: Promise<FileUpload>[] = fileUpload.map((fileItem:FileUpload) => {
      console.log("FileItems",fileItem)
      return new Promise((res, rej) => {
        let reader = new FileReader();
        reader.onload = (ev: ProgressEvent<FileReader>) => {
          const workbook = xlsx.read(ev.target?.result, { type: 'binary', sheetRows: 1 });
          fileItem.totalSheet = workbook.SheetNames.map((sheet) => {
            const workSheet = workbook.Sheets[sheet]
            let Rows: string[] = []
            if (workSheet) {
              const colRange = xlsx.utils.decode_range(workSheet["!ref"]!).e.c;
              Rows = xlsx.utils.sheet_to_json(workSheet, { header: 1, range: `A1:${xlsx.utils.encode_col(colRange)}${1}` });
            }
            return {
              name: sheet,
              sourceColumnList: Rows
            };
          });

          res(fileItem);
        };
        reader.readAsBinaryString(fileItem.file);
      });
    });
    return Promise.all(promises);
  };